import Box from "@mui/material/Box";
import twitchPicture from "../../images/twitch_broadcast.jpg";
import discordPicture from "../../images/discord_background.jpg";
import charityPicture from "../../images/charity_background.jpg";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import "./About.css";
export default function AboutPage() {
  return (
    <>
      <Box
        display='grid'
        gridTemplateColumns='repeat(12, 1fr)'
        gap={2}
        sx={{ padding: "1em" }}
      >
        <Box
          gridColumn='span 5'
          sx={{ paddingLeft: "2em" }}
          className='fullMobile'
        >
          <h2>Twitch Broadcast</h2>
          <h3>twitch.tv/jorbs</h3>
          <h4>Jan 2017 - Present</h4>
          <Box
            display='grid'
            gridTemplateColumns='repeat(12, 1fr)'
            gap={2}
            className='list fullListMobile'
          >
            <Box gridColumn='span 3' className='fullMobile'>
              <div className='secondaryCircle'>
                <span>106k</span>
              </div>
            </Box>
            <Box
              gridColumn='span 9'
              className='text fullMobile'
              sx={{ marginTop: "1em" }}
            >
              <strong>106,000</strong>
              <span>channel followers</span>
            </Box>
            <Box gridColumn='span 3' className='fullMobile'>
              <div className='secondaryCircle'>
                <span>5M+</span>
              </div>
            </Box>
            <Box
              gridColumn='span 9'
              className='text fullMobile'
              sx={{ marginTop: "1em" }}
            >
              <strong>5 Million+</strong>
              <span>hours watched</span>
            </Box>
            <Box gridColumn='span 3' className='fullMobile'>
              <div className='secondaryCircle'>
                <span>$100k+</span>
              </div>
            </Box>
            <Box
              gridColumn='span 9'
              className='text fullMobile'
              sx={{ marginTop: "1em" }}
            >
              <strong>$100,000k+</strong>
              <span>Raised for charity</span>
            </Box>
            <Box gridColumn='span 3' className='fullMobile'>
              <div className='secondaryCircle'>
                <span>1st+</span>
              </div>
            </Box>
            <Box
              gridColumn='span 9'
              className='small text fullMobile'
              sx={{ marginTop: "1.5em !important" }}
            >
              <strong>
                Various world-firsts and world records in strategy gaming.
              </strong>
            </Box>
          </Box>
        </Box>
        <Box
          gridColumn='span 7'
          className='fullMobile'
          sx={{ marginTop: "2em", padding: "1em" }}
        >
          <img
            src={twitchPicture}
            alt='Twitch Broadcast Information'
            className='broadcastPicture'
          />
        </Box>
      </Box>

      <Box
        display='grid'
        gridTemplateColumns='repeat(12, 1fr)'
        gap={2}
        sx={{ marginTop: "2em", padding: "1em" }}
      >
        <Box
          gridColumn='span 5'
          sx={{ paddingLeft: "2em" }}
          className='fullMobile'
        >
          <h2>Discord Community</h2>
          <h3>discord.gg/jorbs</h3>
          <h4>Jan 2017 - Present</h4>
          <Box
            display='grid'
            gridTemplateColumns='repeat(12, 1fr)'
            gap={2}
            className='list'
          >
            <Box gridColumn='span 3' className='fullMobile'>
              <div className='secondaryCircle'>
                <span>6.2K+</span>
              </div>
            </Box>
            <Box
              gridColumn='span 9'
              className='text fullMobile'
              sx={{ marginTop: "1em" }}
            >
              <strong>6,200+</strong>
              <span>community members</span>
            </Box>
            <Box gridColumn='span 3' className='fullMobile'>
              <div className='secondaryCircle'>
                <span>500k+</span>
              </div>
            </Box>
            <Box
              gridColumn='span 9'
              className='text fullMobile'
              sx={{ marginTop: "1em" }}
            >
              <strong>500,000+</strong>
              <span>messages</span>
            </Box>
            <Box gridColumn='span 3' className='fullMobile'>
              <div className='secondaryCircle'>
                <span>
                  <FavoriteRoundedIcon
                    sx={{ fontSize: 70, paddingTop: "12px" }}
                  />
                </span>
              </div>
            </Box>
            <Box
              gridColumn='span 9'
              className='text small fullMobile'
              sx={{ marginTop: "1em" }}
            >
              <strong>
                Inclusive community for conversations on strategy gaming, mental
                health and support, world events, and educational articles and
                podcasts.
              </strong>
            </Box>
          </Box>
        </Box>
        <Box
          gridColumn='span 7'
          className='fullMobile'
          sx={{ marginTop: "2em", padding: "1em" }}
        >
          <img
            src={discordPicture}
            alt='Discord Channel Information'
            className='broadcastPicture'
          />
        </Box>
        <Box
          gridColumn='span 5'
          sx={{ marginTop: "2em", padding: "1em" }}
          className='fullMobile'
        >
          <h2>Charity Fundraising</h2>
          <p>
            I started streaming out of a desire to connect with people and share
            the things I cared about with them. To that end, I've dedicated
            hundreds of hours to (unpaid and unsponsored) charity work on the
            channel. From 24-hour Christmas streams, to a year of
            Climate-related charity work, to pledging to drink only water for a
            year to raise money for Charity: Water.
          </p>

          <p>
            I'm always working on new ways to present important issues to my
            community and motivate them to engage with and think about them for
            themselves.
          </p>
        </Box>
        <Box
          gridColumn='span 7'
          className='fullMobile'
          sx={{ marginTop: "2em", padding: "1em" }}
        >
          <img
            src={charityPicture}
            alt='Charitable Contributions by Jorbs'
            className='broadcastPicture'
          />
        </Box>
      </Box>
    </>
  );
}
