import Box from "@mui/material/Box";
import ContactForm from "../../Components/Data/DisplayItems/Contact";
import "./Contact.css";
import picture from "../../images/contact_background.png";

export default function ContactPage() {
  return (
    <>
      <h1 style={{ marginLeft: "1em" }}>Contact Jorbs</h1>
      <Box display='grid' gridTemplateColumns='repeat(12, 1fr)' gap={2}>
        <Box
          gridColumn='span 6'
          sx={{ paddingLeft: "2em" }}
          className='fullTablet'
        >
          <ContactForm />
        </Box>
        <Box
          gridColumn='span 6'
          className='fullTablet'
          sx={{ paddingLeft: "2em", paddingRight: "2em" }}
        >
          <img
            src={picture}
            alt='Maddy is mad'
            width='100%'
            className='contact_picture image_border hideMobile'
          />
        </Box>
      </Box>
    </>
  );
}
