import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";

const ContactForm = () => {
  const [status, setStatus] = useState("Submit");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    const { Name, Email, Message } = e.target.elements;
    let details = {
      name: Name.value,
      email: Email.value,
      message: Message.value,
    };
    let response = await fetch("https://jorbs.tv:5000/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    setStatus("Submit");
    let result = await response.json();
    alert(result.status);
  };
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor='name' aria-label='Name'>
          Name:
        </label>
        <TextField
          required
          id='Name'
          fullWidth
          label='Name'
          variant='filled'
          placeholder='Name'
        />
      </div>
      <div>
        <label htmlFor='email' aria-label='Email'>
          Email:
        </label>
        <TextField
          margin='dense'
          fullWidth
          required
          id='Email'
          label='Email'
          variant='filled'
          type='email'
          placeholder='Email'
        />
      </div>
      <div>
        <label htmlFor='message' aria-label='Message'>
          Message:
        </label>
        <TextField
          multiline
          rows={4}
          id='Message'
          variant='filled'
          placeholder='Message'
        />
      </div>
      <Button
        type='submit'
        variant='contained'
        endIcon={<SendIcon />}
        color='secondary'
      >
        {status}
      </Button>
    </form>
  );
};

export default ContactForm;
