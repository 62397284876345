import merchImage from "../../images/merch.jpg";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import PlainLink from "@mui/material/Link";

export default function Merch() {
  return (
    <Card
      xs={12}
      lg={4}
      sx={{
        gap: 2,
        p: 0,
        marginRight: "1em",
        maxWidth: 400,
        border: "1px solid #333639",
        backgroundColor: "#42477a",
        marginTop: "2em",
        textAlign: "left",
      }}
    >
      <PlainLink
        underline='none'
        target='_blank'
        sx={{
          color: "#fff",
        }}
        href='https://www.bonfire.com/jorbs/'
        title='Visit our Merch Store'
      >
        <CardMedia
          component='img'
          height='300'
          image={merchImage}
          alt='Visit our Merch Store'
        />
      </PlainLink>
      <CardContent>
        <PlainLink
          underline='none'
          target='_blank'
          sx={{
            color: "#fff",
          }}
          href='https://www.bonfire.com/jorbs/'
          title='Visit our Merch Store'
        >
          <Typography gutterBottom variant='h5' component='div'>
            Merchandise
          </Typography>
        </PlainLink>
      </CardContent>
    </Card>
  );
}
