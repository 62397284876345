import { Timeline } from "react-twitter-widgets";

export default function TwitterFeed() {
  return (
    <Timeline
      className='timeline'
      dataSource={{ sourceType: "profile", screenName: "JoINrbs" }}
      options={{
        chrome: "noheader, nofooter, transparent",
        theme: "dark",
        width: "400",
        height: "400",
        align: "left",
      }}
    />
  );
}
