import MainNavigation from "./MainNavigation";
import Grid from "@mui/material/Grid";
import "./Header.css";
import SocialMediaAggregate from "./SocialMediaAggregate";
import Wizard from "../../images/Logo.png";

export default function Header() {
  return (
    <Grid container spacing={3} sx={{ py: 0 }} className='background_image'>
      <Grid item xs={12} sm={2} md={2} lg={2}>
        <div className='logoContainer'>
          <img src={Wizard} alt='jorbs' className='logoWizard' />
        </div>
      </Grid>
      <Grid item xs={12} sm={10} md={7} lg={7}>
        <MainNavigation />
        <SocialMediaAggregate className='tabletOrSmaller' />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} sx={{ justifyContent: "start" }}>
        <SocialMediaAggregate className='desktopOrLarger' />
      </Grid>
    </Grid>
  );
}
