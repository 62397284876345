import * as React from "react";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import PlainLink from "@mui/material/Link";
import "./Footer.css";
export default function Footer() {
  return (
    <Box
      className='footerBox'
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "50vh",
      }}
    >
      <Box
        className='footerLinks'
        component='footer'
        sx={{
          py: 3,
          px: 2,
          mt: "auto",
          backgroundColor: "#26214a",
          color: "#ffffff",
          textAlign: "center",
          a: {
            color: "white",
            textDecoration: "none",
            margin: "15px",
            ":hover": {
              textDecoration: "underline",
            },
          },
          alignItems: "center",
          justifyContent: "space-evenly",
          display: "flex",
        }}
      >
        <Container maxWidth='md' className='footerMax'>
          <Link to={"/"}>Home</Link>
          <Link to={"/about"}>About</Link>
          <Link to={"/goals"}>Goals</Link>
          <PlainLink href='https://essays.jorbs.tv/' className='footerSep'>
            Essays
          </PlainLink>
          <PlainLink
            href='https://www.bonfire.com/jorbs/'
            className='footerSep'
          >
            Merchandise
          </PlainLink>
          <PlainLink
            href='https://salamanderstreet.com/product/before-we-go-live-paperback/'
            className='footerSep'
          >
            Book Preorder
          </PlainLink>
          <Link to={"/contact"}>Contact</Link>
        </Container>
      </Box>
    </Box>
  );
}
