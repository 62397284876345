import picture from "../../images/jorbs_goals.png";
import Box from "@mui/material/Box";
import "./Goals.css";

export default function Goals() {
  return (
    <>
      <Box display='grid' gridTemplateColumns='repeat(12, 1fr)' gap={2}>
        <Box
          gridColumn='span 6'
          sx={{ paddingLeft: "2em" }}
          className='fullTablet'
        >
          <h1 style={{ marginBottom: "1em", marginLeft: "0" }}>Goals</h1>
          <p>
            I grew up playing strategy games on my grandfather's floor while he
            read his Bible, and they became meditative and calming to me through
            that experience. I take complicated and daunting games and find
            simple ways to introduce viewers to all of their beautiful
            complexities, while pushing myself to achieve mastery of them.
            Before streaming I was a world class Magic: The Gathering and Poker
            player, and on stream viewers have watched me master games from the
            XCOM series, Faster Than Light, and Slay the Spire.
          </p>
          <p>
            I want people to be able to put aside the stressors from their day
            and unwind by engaging with interesting and educational content.
          </p>
          <p>
            Create a relaxing, inclusive, and educational community for
            strategy-oriented gamers.
          </p>
        </Box>
        <Box
          gridColumn='span 6'
          className='fullTablet'
          sx={{ paddingLeft: "2em", paddingRight: "2em" }}
        >
          <img
            src={picture}
            alt='Jorbs Goals'
            width='90%'
            className='float_right'
          />
        </Box>
      </Box>
    </>
  );
}
