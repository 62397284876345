import React from "react";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Link from "@mui/material/Link";
import "./videoItem.css";
export default function VideoItem(props) {
  const entities = {
    "&#39;": "'",
    "&quot;": '"',
  };
  return (
    <Link
      href={props.url}
      rel='noreferrer'
      target='_blank'
      sx={{ color: "text.primary" }}
      underline='hover'
    >
      <ImageListItem
        key={props.id}
        className='videoListItem'
        sx={{
          transition: "transform 0.15s ease-in-out",
          ":hover": {
            transform: "scale3d(1.05, 1.05, 1)",
          },
        }}
      >
        <img
          className='imageBorder'
          src={props.thumbnail}
          alt={props.title}
          loading='lazy'
        />
        <ImageListItemBar
          title={props.title.replace(/&#?\w+;/, (match) => entities[match])}
          position='below'
        />
      </ImageListItem>
    </Link>
  );
}
