import React, { useState, useEffect } from "react";
import VideoItem from "./DisplayItems/VideoItem.js";
import { imageListItemClasses } from "@mui/material/ImageListItem";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import PulseLoader from "react-spinners/PulseLoader";

export default function TwitchFeed() {
  const [videos, setVideos] = useState(null);
  const baseUrl = "https://www.jorbs.tv:5000/api/twitch";
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    let isLoaded = true;
    (async () => {
      try {
        await fetch(`${baseUrl}`)
          .then((response) => response.json())
          .then((response) => {
            isLoaded &&
              setVideos(
                response.data
                  .filter((obj) => {
                    return obj.thumbnail_url !== "";
                  })
                  .slice(0, 5)
              );
          })
          .then(setLoading(true));
      } catch (error) {
        console.log(error);
      }
    })();
    return function cleanup() {
      isLoaded = false;
    };
  }, []);

  if (videos == null) {
    return (
      <Box
        className='margin_remove_mobile text_align_center'
        sx={{
          padding: "20px",
          alignItems: "center",
          justifyItems: "center",
          display: "grid",
        }}
      >
        <PulseLoader loading={loading} color='white' size={15} />
      </Box>
    );
  }

  if (videos != null) {
    return (
      <Container
        sx={{ marginLeft: "1em" }}
        className='margin_remove_mobile text_align_center'
      >
        <Box
          className='margin_remove_mobile text_align_center'
          sx={{
            padding: "1em",
            height: {
              mobile: "auto",
              bigMobile: "auto",
              tablet: "auto",
              desktop: "212px",
            },
            alignItems: "center",
            justifyItems: "center",
            display: "grid",
            backgroundColor: "background.paper",
            gridTemplateColumns: {
              mobile: "repeat(1, 1fr)",
              bigMobile: "repeat(1, 1fr)",
              tablet: "repeat(2, 1fr)",
              desktop: "repeat(5, 1fr)",
            },
            [`& .${imageListItemClasses.root}`]: {
              display: "flex",
              flexDirection: "column",
            },
          }}
        >
          {videos.map((video) => (
            <VideoItem
              key={video.id}
              url={video.url}
              id={video.id}
              thumbnail={video.thumbnail_url
                .replace("%{width}", "320")
                .replace("%{height}", "180")}
              title={video.title}
            />
          ))}
        </Box>
      </Container>
    );
  }
}
