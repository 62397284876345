import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import YoutubeFeed from "../Data/YoutubeFeed";
import TwitchFeed from "../Data/TwitchFeed";
import TwitterFeed from "../Data/TwitterFeed";
import BookPreorder from "../Data/BookPreorder";
import Merch from "../Data/Merch";

export default function DefaultPage() {
  return (
    <Grid
      container
      sx={{ justifyContent: "space-between" }}
      className='margin_remove_mobile text_align_center'
    >
      <Grid item xs={12} lg={8}>
        <Box
          sx={{
            p: 2,
            display: "grid",
            gap: 2,
          }}
        >
          <h2
            className='margin_remove_mobile text_align_center'
            style={{
              marginTop: 0,
              marginBottom: 0,
              marginLeft: "1em",
              fontSize: "2em",
            }}
          >
            Latest Youtube Feed
          </h2>
          <YoutubeFeed other={false} />
        </Box>
        <Box
          sx={{
            p: 2,
            display: "grid",
            gap: 2,
          }}
        >
          <h2
            className='margin_remove_mobile text_align_center'
            style={{
              marginTop: "1em",
              marginBottom: 0,
              marginLeft: "1em",
              fontSize: "2em",
            }}
          >
            Recent Twitch Streams
          </h2>
          <TwitchFeed />
        </Box>
        <Box
          sx={{
            p: 2,
            display: "grid",
            gap: 2,
          }}
        >
          <h2
            className='margin_remove_mobile text_align_center'
            style={{
              marginTop: "1em",
              marginBottom: 0,
              marginLeft: "1em",
              fontSize: "2em",
            }}
          >
            Latest Variety Youtube Feed
          </h2>
          <YoutubeFeed other={true} />
        </Box>
      </Grid>
      <Grid item xs={12} lg={4} align='center'>
        <BookPreorder />
        <Box
          sx={{
            p: 2,
            bgcolor: "background.default",
            display: "grid",
            gap: 2,
            paddingRight: "2em",
          }}
        >
          <h2
            className='margin_remove_mobile'
            style={{
              marginTop: "1em",
              textAlign: "left",
              marginBottom: 0,
              fontSize: "1.5em",
            }}
          >
            Latest Tweets
          </h2>
          <TwitterFeed />
        </Box>
        <Merch />
      </Grid>
    </Grid>
  );
}
