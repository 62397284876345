import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Link } from "react-router-dom";
import PlainLink from "@mui/material/Link";
import "./MainNavigation.css";

export default function MainNavigation() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label='Main Navigation'
        className='mainNavigation'
        indicatorColor='secondary'
        textColor='primary'
        sx={{
          "& .MuiTabs-flexContainer": {
            flexWrap: "wrap",
          },
        }}
      >
        <Tab component={Link} label='Home' to={"/"} />
        <Tab component={Link} label='About' to={"/about"} />
        <Tab component={Link} label='Goals' to={"/goals"} />
        <Tab
          component={PlainLink}
          label='Essays'
          href='https://essays.jorbs.tv/'
        />
        <Tab
          target='_blank'
          component={PlainLink}
          label='Merchandise'
          href={"https://www.bonfire.com/jorbs/"}
        />
        <Tab
          target='_blank'
          component={PlainLink}
          label='Book Preorder'
          href={
            "https://salamanderstreet.com/product/before-we-go-live-paperback/"
          }
        />
        <Tab component={Link} label='Contact' to={"/contact"} />
      </Tabs>
    </>
  );
}
