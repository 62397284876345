import bookImage from "../../images/before_we_go_live.jpg";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import PlainLink from "@mui/material/Link";

export default function BookPreorder() {
  return (
    <Card
      xs={12}
      lg={4}
      sx={{
        gap: 2,
        p: 0,
        marginRight: "1em",
        maxWidth: 400,
        border: "1px solid #333639",
        backgroundColor: "#42477a",
        marginTop: "2em",
        textAlign: "left",
      }}
    >
      <PlainLink
        underline='none'
        target='_blank'
        sx={{
          color: "#fff",
        }}
        href='https://salamanderstreet.com/product/before-we-go-live-paperback/'
        title='Before We Go Live Preorder'
      >
        <CardMedia
          component='img'
          height='200'
          image={bookImage}
          alt='Before We Go Live - Book Preorder'
        />
      </PlainLink>
      <CardContent>
        <PlainLink
          underline='none'
          target='_blank'
          sx={{
            color: "#fff",
          }}
          href='https://salamanderstreet.com/product/before-we-go-live-paperback/'
          title='Before We Go Live Preorder'
        >
          <Typography gutterBottom variant='h5' component='div'>
            Before We Go Live
            <Typography variant='body2' color='text.secondary'>
              Navigating the Abusive World of Online Entertainment by Stephen
              Flavall (paperback)
            </Typography>
          </Typography>
        </PlainLink>
      </CardContent>
      <CardActions>
        <PlainLink
          target='_blank'
          sx={{
            textTransform: "uppercase",
            color: "#fff",
            fontSize: "medium",
            fontWeight: "bold",
            paddingLeft: ".5em",
          }}
          href='https://salamanderstreet.com/product/before-we-go-live-paperback/'
          title='Before We Go Live Preorder'
        >
          Learn More
        </PlainLink>
      </CardActions>
    </Card>
  );
}
