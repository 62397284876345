import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

//'Changa One'
const theme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#1d3666",
      light: "#ffffff",
      dark: "#aaa8c7",
    },
    secondary: {
      main: "#e86cb0",
    },
    background: {
      paper: "#2a2d4f",
      default: "#1A1A40",
      secondary: "#1d3666",
    },
    text: {
      secondary: "#FA58B6",
      primary: "#ffffff",
    },
  },
  breakpoints: {
    values: {
      mobile: 0,
      bigMobile: 350,
      tablet: 650,
      desktop: 900,
    },
  },
});

function App(props) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className='App'>{props.children}</div>
    </ThemeProvider>
  );
}

export default App;
