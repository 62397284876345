import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Header from "./Components/Layout/Header";
import Footer from "./Components/Layout/Footer";
import DefaultPage from "./Components/Pages/DefaultPage";
import AboutPage from "./Components/Pages/About";
import GoalsPage from "./Components/Pages/Goals";
import ContactPage from "./Components/Pages/Contact";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Header />
      <App>
        <Routes>
          <Route path='/' element={<DefaultPage />}></Route>
          <Route path='/about' element={<AboutPage />}></Route>
          <Route path='/goals' element={<GoalsPage />}></Route>
          <Route path='/contact' element={<ContactPage />}></Route>
        </Routes>
      </App>
      <Footer />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
